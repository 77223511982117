import {Maybe} from '@core/common';
import {UserBase} from '@features/users/models';

export interface Organization extends OrganizationDto {
  countryObj?: Country;
  applicationsObj?: Application[];
}

export interface Country {
  id: number;
  code: string;
  name: string;
}

export interface Application {
  id: number;
  shortFormTitle: string;
  longFormTitle: string;
  organizations?: Organization[];
}

export interface OrganizationCreateReq {
  organizationName: string;
  applications: number[];
  referenceCode: string;
  country: number;
  location: string;
  comment: string;
}

export enum OrganizationStatus {
  active = 'ACTIVE',
  inactive = 'INACTIVE'
}

export interface OrganizationDto {
  id?: number;
  organizationName?: string;
  applications?: number[];
  referenceCode?: string;
  country?: number;
  location?: string;
  createdDate?: string;
  updatedDate?: string;
  activeProjects?: string;
  status?: OrganizationStatus;
  updatedBy?: string;
  branchName?: string;
  comment?: string;
  primaryContactUsers?: UserBase[];
}

export interface OrganizationFormValue {
  id: Maybe<number>;
  organizationName: string;
  applications: number[];
  activeProjects: string;
  referenceCode: string;
  country: Maybe<number>;
  location: string;
  createdDate: string;
  updatedDate: string;
  status: Maybe<OrganizationStatus>;
  updatedBy: string;
  branchName: string;
  primaryContacts: number[];
  comment: string;
}
