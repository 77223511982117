import {ChangeDetectionStrategy, Component, inject, Input, TemplateRef} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {MatDialog} from '@angular/material/dialog';
import {MatIcon} from '@angular/material/icon';
import {tap} from 'rxjs';

import {DEFAULT_MODAL_WIDTH} from '@core/common';

@Component({
  selector: 'aps-project-create-btn',
  templateUrl: './project-create-btn.component.html',
  standalone: true,
  imports: [
    MatIcon,
    MatButton
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectCreateBtnComponent {
  private readonly matDialog = inject(MatDialog);
  @Input() templateRef!: TemplateRef<unknown>;

  showDialog(): void {
    this.openCreateDialog();
  }

  private openCreateDialog(): void {
    this.matDialog
      .open(this.templateRef, {
        width: DEFAULT_MODAL_WIDTH,
        closeOnNavigation: true
      })
      .afterClosed()
      .pipe(
        tap(result => {
          if (result.openOnEdit) {
            this.matDialog.open(this.templateRef, {
              width: DEFAULT_MODAL_WIDTH,
              data: result.project,
              closeOnNavigation: true
            });
          }
        })
      ).subscribe();
  }
}
