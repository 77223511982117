<aps-entity-create-dialog
  [title]="isUpdateMode ? 'Organization Overview' : 'Create a new organization'"
  [icon]="isUpdateMode ? 'home-city-outline' : 'add-organization'"
  [submitBtnText]="isUpdateMode ? 'Save' : 'Create'"
  [submitBtnDisabled]="submitBtnDisabled"
  [apsBtnPending]="(createPending$ | async) || (updatePending$ | async)"
  (submitted)="submit()">
  <form [formGroup]="form" autocomplete="off">
    <div class="grid grid-cols-12">
      <div class="col-span-6 pt-1 pr-6">

        <div class="text-sm font-semibold">General Information</div>

        <div class="bg-light px-4 pt-4 mb-6">
          <label class="required">Organization Name</label>
          <mat-form-field class="w-full" appearance="outline">
            @if (form.controls.organizationName.pending) {
              <mat-spinner matPrefix
                           class="mx-3"
                           mode="indeterminate"
                           [diameter]="16" />
            } @else {
              <mat-icon matPrefix
                        class="color-medium"
                        svgIcon="home-city-outline" />
            }
            <input formControlName="organizationName" type="text" matInput placeholder="Add organization name">
            @if (form.controls.organizationName.invalid) {
              <mat-error>
                @if (form.controls.organizationName.errors?.['required']) {
                  <span>This field is required</span>
                } @else if (form.controls.organizationName.errors?.['minlength']) {
                  <span>Min length is {{organizationNameMinLength}}</span>
                } @else if (form.controls.organizationName.errors?.['maxlength']) {
                  <span>Max length is {{organizationNameMaxLength}}</span>
                } @else if (form.controls.organizationName.errors?.['alreadyExists']) {
                  <span>Already exists</span>
                }
              </mat-error>
            }
          </mat-form-field>

          <label>Application(s)</label>
          <mat-form-field class="w-full" appearance="outline">
            <mat-icon matPrefix class="color-medium" svgIcon="book-opened-outline" />
            <mat-select formControlName="applications"
                        multiple
                        placeholder="Add Application(s)">
              @for (application of applications$ | async; track application.id) {
                <mat-option [value]="application?.id">{{application.longFormTitle}}</mat-option>
              }
            </mat-select>
          </mat-form-field>

          <div class="grid grid-cols-12">
            <div class="col-span-8">
              <label class="required">Customer Number</label>
              <mat-form-field class="w-full mb-6" appearance="outline">
                @if (form.controls.referenceCode.pending) {
                  <mat-spinner matPrefix
                               class="mx-3"
                               mode="indeterminate"
                               [diameter]="16" />
                } @else {
                  <mat-icon matPrefix class="color-medium" svgIcon="code-outline" />
                }
                <input formControlName="referenceCode" type="text" placeholder="Enter a customer number" matInput>
                @if (form.controls.referenceCode.invalid) {
                  <mat-error>
                    @if (form.controls.referenceCode.errors?.['required']) {
                      <span>This field is required</span>
                    } @else if(form.controls.referenceCode.errors?.['maxlength']) {
                      <span>Max length is {{referenceCodeMaxLength}}</span>
                    }  @else if(form.controls.referenceCode.errors?.['alreadyExists']) {
                      <span>Already exists</span>
                    }
                  </mat-error>
                }
              </mat-form-field>

              <label>Country</label>
              <mat-form-field class="w-full" appearance="outline" floatLabel="always">
                <mat-icon matPrefix class="color-medium" svgIcon="earth" />
                <mat-select formControlName="country" placeholder="Select a Country">
                  <mat-option [value]="null">N/A</mat-option>
                  @for (country of countries$ | async; track country.id) {
                    <mat-option [value]="country?.id">{{country.name}}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <label>Location</label>
          <mat-form-field class="w-full" appearance="outline">
            <mat-icon matPrefix class="color-medium" svgIcon="marker" />
            <input formControlName="location" type="text" matInput placeholder="Add Location">
            @if (form.controls.location.invalid) {
              <mat-error>
                @if (form.controls.location.errors?.['maxlength']) {
                  <span>Max length is {{locationMaxLength}}</span>
                }
              </mat-error>
            }
          </mat-form-field>
        </div>

        <label>APS Comment</label>
        <mat-form-field class="w-full" appearance="outline" floatLabel="always">
          <mat-icon matPrefix class="color-medium" svgIcon="comment" />
          <textarea formControlName="comment" matInput placeholder="Add a comment"></textarea>
          @if (form.controls.comment.invalid) {
            <mat-error>
              @if (form.controls.comment?.errors?.['maxlength']) {
                <span>Max length is {{commentMaxLength}}</span>
              }
            </mat-error>
          }
        </mat-form-field>
      </div>
      <div class="col-span-6 pt-1 pl-6">
        @if (isUpdateMode) {
          <mat-accordion class="example-headers-align">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Primary Contacts
                </mat-panel-title>
              </mat-expansion-panel-header>
              @for (user of organization?.primaryContactUsers; track user.id) {
                <aps-user-card class="block mb-4" [user]="user" />
              }
            </mat-expansion-panel>
          </mat-accordion>
        }
      </div>
    </div>
  </form>
  <ng-container extraControls>
    <div class="grid justify-end">
      @if (isUpdateMode) {
        <aps-organization-deactivate [organization]="organization"
                                     (changed)="onOrganizationStatusChanged($event)" />
      }
    </div>
  </ng-container>

  <div class="divider-shadow"></div>
</aps-entity-create-dialog>
