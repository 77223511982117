export interface BaseEntity<T = string> {
  id: T;
  name?: string;
  isDisabled?: boolean;
}

export interface ListResponse<T = any> {
  data: T[];
  pagination: PaginationRes;
}

export type Maybe<T> = T | null;

export interface Pagination {
  pageIndex: number;
  pageSize: number;
}

export interface GpsCoordinates {
  id?: number;
  latitude: number;
  longitude: number;
  altitude: number;
}

export interface PaginationRes {
  currentPage?: number;
  hasNext?: boolean;
  hasPrevious?: boolean;
  nextPage?: string;
  pageSize?: number;
  previousPage?: string;
  totalCount?: number;
  totalPages?: number;
}

export interface ImageMetadata {
  url: string;
  width: number;
  height: number;
}

export type DialogMode = 'create' | 'update';


export enum ErrorCode {
  INVALID_CSV = 1,
  INVALID_KML_KMZ = 2,
  INVALID_BP_FILE = 3,
}

export class BackendError extends Error {
  readonly code: ErrorCode;
  constructor(message: string, code: ErrorCode) {
    super(message);
    this.code = code;
  }
}

export interface FileChangeEvent {
  fileBase64: string;
  file: File;
}
