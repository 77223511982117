import {AbstractControl} from '@angular/forms';
import {map, of, switchMap, timer} from 'rxjs';

import {isArraysAreEqual, Maybe} from '@core/common';
import {Organization, OrganizationFormValue} from '@features/organizations/models';
import {OrganizationService} from '@features/organizations/services';


export const isOrganizationsAreEqual = (o1: Partial<Organization>, o2: Partial<OrganizationFormValue>): boolean =>
  (o1?.organizationName === o2?.organizationName)
  && (o1?.status === o2?.status)
  && isArraysAreEqual(o1?.applications!, o2?.applications!)
  && (o1?.country === o2?.country)
  && (o1?.location === o2?.location)
  && (o1?.comment === o2?.comment);

export class ValidateNameNotTaken {
  static createValidator(organizationService: OrganizationService, currentOrganization: Maybe<Organization> = null) {
    return (control: AbstractControl<string>) => {
      if (currentOrganization?.organizationName) {
        if (currentOrganization?.organizationName === control.value) {
          return of(null);
        }
      }

      return timer(400).pipe(
        switchMap(() => organizationService.searchByName(control.value)),
        map((result) => result?.data?.length ? { alreadyExists: true } : null)
      );
    };
  }
}

export class ValidateRefCodeNotTaken {
  static createValidator(organizationService: OrganizationService, currentOrganization: Maybe<Organization> = null) {
    return (control: AbstractControl<string>) => {
      if (currentOrganization?.referenceCode) {
        if (currentOrganization?.referenceCode === control.value) {
          return of(null);
        }
      }

      return timer(400)
        .pipe(
          switchMap(() => organizationService.searchByRefCode(control.value)),
          map((result) => result?.data?.length ? { alreadyExists: true } : null)
        );
    };
  }
}
