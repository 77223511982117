import {inject, Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {catchError, map, of} from 'rxjs';
import {tap} from 'rxjs/operators';

import {ApplicationService, CountryService} from '@core/services';
import {OrganizationInactiveNotificationComponent} from '@features/organizations/components';
import {OrganizationDto, OrganizationStatus} from '@features/organizations/models';
import {OrganizationApiService} from '@features/organizations/services';
import {UserRole} from '@features/users/models';

@Injectable({ providedIn: 'root' })
export class AppInitializerService {
  private readonly organizationApiService = inject(OrganizationApiService);
  private readonly countryService = inject(CountryService);
  private readonly applicationService = inject(ApplicationService);
  private readonly matDialog = inject(MatDialog);

  init(userRoles: string[]): any {
    if (this.hasPeRole(userRoles)) {
      this.organizationApiService.getOrganizations({ pageIndex: 0, pageSize: 1 }, null)
        .pipe(
          catchError(() => of(null)),
          map(res => res?.data[0]!),
          tap(organization => {
            this.checkIfShouldShowOrgDeactivatedNotification(organization);
          })
        ).subscribe();
    }
    this.countryService.init();
    this.applicationService.init();
  }

  private checkIfShouldShowOrgDeactivatedNotification(currentUserOrganization: OrganizationDto): void {
    if (!currentUserOrganization || currentUserOrganization.status === OrganizationStatus.inactive) {
      this.showDialog();
    }
  }

  private hasPeRole(userRoles: string[]): boolean {
    return Boolean(userRoles.includes(UserRole.PE));
  }

  private showDialog(): void {
    this.matDialog.open(OrganizationInactiveNotificationComponent, {
      width: '512px',
      closeOnNavigation: false,
      disableClose: true
    });
  }
}
