import {UserRole} from '@features/users/models';

export const USER_ROLES_MAP = new Map<UserRole, string>()
  .set(UserRole.PE, 'Project Engineer')
  .set(UserRole.APS_PE, 'APS Project Engineer')
  .set(UserRole.TECHNICIAN, 'Technician');

export const USER_ROLES_MAP_FOR_APS_PE = new Map<UserRole, string>()
  .set(UserRole.PE, 'Project Engineer')
  .set(UserRole.TECHNICIAN, 'Technician');

export const USER_ROLES_MAP_FOR_PE = new Map<UserRole, string>()
  .set(UserRole.TECHNICIAN, 'Technician');
