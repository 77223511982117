import {HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  finalize,
  map,
  Observable,
  ReplaySubject,
  switchMap,
  tap,
  throwError
} from 'rxjs';

import {ListResponse, Maybe, Pagination} from '@core/common';
import {ApplicationService, CountryService} from '@core/services';
import {
  Application,
  Country,
  Organization,
  OrganizationCreateReq,
  OrganizationStatus
} from '@features/organizations/models';
import {AgGridSortState} from '@shared/models';

import {OrganizationApiService} from './organization-api.service';


@Injectable({ providedIn: 'root' })
export class OrganizationService {
  private readonly organizationApiService = inject(OrganizationApiService);
  private readonly countryService = inject(CountryService);
  private readonly applicationService = inject(ApplicationService);
  private _refreshList$ = new BehaviorSubject<boolean>(false);
  private _pendingList$ = new BehaviorSubject<boolean>(false);
  private _errorList$ = new BehaviorSubject<Maybe<HttpErrorResponse>>(null);
  private _getByIdPending$ = new BehaviorSubject<boolean>(false);
  private _createPending$ = new BehaviorSubject<boolean>(false);
  private _updatePending$ = new BehaviorSubject<boolean>(false);
  private _deletePending$ = new BehaviorSubject<boolean>(false);
  private pagination$ = new ReplaySubject<Pagination>(1);
  private sortingState$ = new BehaviorSubject<Maybe<AgGridSortState[]>>(null);

  pendingList$ = this._pendingList$.asObservable();
  errorList$ = this._errorList$.asObservable();
  getByIdPending$ = this._getByIdPending$.asObservable();
  createPending$ = this._createPending$.asObservable();
  updatePending$ = this._updatePending$.asObservable();
  deletePending$ = this._deletePending$.asObservable();

  getOrganizations(status: OrganizationStatus): Observable<ListResponse<Organization>> {
    return combineLatest([
      this.pagination$.asObservable(),
      this.sortingState$.asObservable(),
      this.countryService.countriesMap$,
      this.applicationService.applicationsMap$,
      this._refreshList$
    ])
      .pipe(
        tap(() => {
          this._pendingList$.next(true);
          this._errorList$.next(null);
        }),
        switchMap(([
                     pagination,
                     sorting,
                     countries,
                     applications
                   ]) => this.organizationApiService.getOrganizations(pagination, status, sorting)
          .pipe(
            map((organizations) => this.mapList(organizations, countries, applications)),
            finalize(() => {
              this._pendingList$.next(false);
            }),
            catchError((err: HttpErrorResponse) => {
              this._errorList$.next(err);
              return throwError(() => err);
            })
          )
        )
      );
  }

  getById(id: number): Observable<Organization> {
    this._getByIdPending$.next(true);
    return this.organizationApiService.getById(id)
      .pipe(finalize(() => {
        this._getByIdPending$.next(false);
      }));
  }

  create(organizationCreateReq: OrganizationCreateReq): Observable<null> {
    this._createPending$.next(true);
    return this.organizationApiService.create(organizationCreateReq)
      .pipe(
        finalize(() => {
          this._createPending$.next(false);
        }),
        tap(() => {
          this.refreshList();
        })
      );
  }

  update(organization: Organization): Observable<Organization> {
    this._updatePending$.next(true);
    return this.organizationApiService.update(organization)
      .pipe(
        finalize(() => {
          this._updatePending$.next(false);
        })
      );
  }

  changeStatus(id: number, status: OrganizationStatus): Observable<Organization> {
    this._deletePending$.next(true);
    return this.organizationApiService.status(id, status)
      .pipe(
        finalize(() => {
          this._deletePending$.next(false);
        }),
        tap(() => {
          this.refreshList();
        })
      );
  }

  searchByName(organizationName: string): Observable<ListResponse<Organization>> {
    return this.organizationApiService.searchByName(organizationName);
  }

  searchByRefCode(referenceCode: string): Observable<ListResponse<Organization>> {
    return this.organizationApiService.searchByRefCode(referenceCode);
  }

  refreshList(): void {
    this._refreshList$.next(!this._refreshList$.value);
  }

  setPagination(pagination: Pagination): void {
    this.pagination$.next(pagination);
  }

  setSorting(event: AgGridSortState[] | null): void {
    this.sortingState$.next(event);
  }

  private mapList(
    organizations: ListResponse<Organization>,
    countriesMap: Map<number, Country>,
    applicationsMap: Map<number, Application>
  ): ListResponse<Organization> {
    return {
      ...organizations,
      data: organizations.data.map((org) => ({
        ...org,
        countryObj: countriesMap.get(org.country!),
        applicationsObj: org.applications?.map((a) => applicationsMap.get(a)!)
      }))
    };
  }
}
