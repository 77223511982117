import {HttpClient, HttpParams} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {Observable} from 'rxjs';

import {ListResponse, Maybe, Pagination} from '@core/common';
import {Organization, OrganizationCreateReq, OrganizationDto, OrganizationStatus} from '@features/organizations/models';
import {AgGridSort, AgGridSortState} from '@shared/models';

@Injectable({ providedIn: 'root' })
export class OrganizationApiService {
  private readonly httpClient = inject(HttpClient);

  getOrganizations(pagination: Pagination, status: Maybe<OrganizationStatus>, sorting: Maybe<AgGridSortState[]> = null): Observable<ListResponse<OrganizationDto>> {
    let params = new HttpParams({
      fromObject: {
        ...(status ? { status } : null),
        pageNumber: pagination.pageIndex + 1,
        pageSize: pagination.pageSize
      }
    });

    if (sorting?.length) {
      params = sorting.reduce((parms, el) => {
        const sortingOrder = el.order === AgGridSort.DESC ? 'OrderByDescending' : 'OrderBy';
        return parms.append('PropertyNames', `${this.mapSortPropertyToDtoProperty(el.propertyName!)},${sortingOrder}`);
      }, params);
    }

    return this.httpClient.get<ListResponse<OrganizationDto>>(`${environment.baseURL}/organizations`, { params });
  }

  searchByName(name: string): Observable<ListResponse<Organization>> {
    const params: any = {
      name,
      exact: true
    };

    return this.httpClient.get<ListResponse<Organization>>(`${environment.baseURL}/organizations`, { params });
  }

  searchByRefCode(referenceCode: string): Observable<ListResponse<Organization>> {
    const params: any = {
      referenceCode,
      exact: true
    };

    return this.httpClient.get<ListResponse<Organization>>(`${environment.baseURL}/organizations`, { params });
  }

  getById(id: number): Observable<Organization> {
    return this.httpClient.get<Organization>(`${environment.baseURL}/organizations/${id}`);
  }

  create(organizationCreateReq: OrganizationCreateReq): Observable<null> {
    return this.httpClient.post<null>(`${environment.baseURL}/organizations`, organizationCreateReq);
  }

  update(organization: Organization): Observable<Organization> {
    return this.httpClient.put<Organization>(`${environment.baseURL}/organizations/${organization?.id}`, organization);
  }

  status(id: number, status: OrganizationStatus): Observable<any> {
    return this.httpClient.put<Organization>(`${environment.baseURL}/organizations/${id}/status/${status}`, null);
  }

  private mapSortPropertyToDtoProperty(propertyName: string): string {
    if (propertyName === 'status') {
      return 'isActive';
    }
    if (propertyName === 'applicationsObj') {
      return 'applications';
    }
    return propertyName;
  }
}
