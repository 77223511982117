import {ChangeDetectionStrategy, Component, inject, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {PageEvent} from '@angular/material/paginator';
import {ColDef, GridApi, GridOptions, RowClickedEvent} from 'ag-grid-community';
import {NgxPermissionsModule} from 'ngx-permissions';
import {filter, switchMap, take, tap, withLatestFrom} from 'rxjs';

import {DEFAULT_MODAL_WIDTH, INITIAL_PAGINATION_CONFIG, Maybe} from '@core/common';
import {ApplicationService, CountryService} from '@core/services';
import {Application, Country, Organization, OrganizationStatus} from '@features/organizations/models';
import {OrganizationService} from '@features/organizations/services';
import {UserRole} from '@features/users/models';
import {AgGridWrapperComponent, BaseListComponent} from '@shared/components';
import {AgGridSortState, AgGridState} from '@shared/models';

import {OrganizationCreateComponent} from '../organization-create';

@Component({
  selector: 'aps-organizations-list',
  templateUrl: './organizations-list.component.html',
  standalone: true,
  imports: [AgGridWrapperComponent, NgxPermissionsModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationsListComponent extends BaseListComponent implements OnInit {
  private readonly organizationService = inject(OrganizationService);
  private readonly dialog = inject(MatDialog);
  private readonly countryService = inject(CountryService);
  private readonly applicationService = inject(ApplicationService);

  @Input() status: Maybe<OrganizationStatus> = null;
  @Input() columnDefs!: ColDef[];
  readonly userRole = UserRole;

  gridState!: AgGridState<Organization>;

  gridOptions: GridOptions = {
    multiSortKey: 'ctrl',
    rowClassRules: {
      'color-medium': `data.status === "${OrganizationStatus.inactive}"`
    }
  };

  override ngOnInit(): void {
    super.ngOnInit();
    this.organizationService.setPagination({
      ...INITIAL_PAGINATION_CONFIG,
      pageSize: this.pageSize
    });
    this.initGridSetting();
  }

  onSortChanged(event: AgGridSortState[] | null): void {
    this.organizationService.setSorting(event);
  }

  onRowClicked(event: RowClickedEvent): void {
    const organization: Organization = event.data;
    this.organizationService.getByIdPending$
      .pipe(
        take(1),
        filter(isPending => !isPending),
        switchMap(() => this.organizationService.getById(organization.id!)),
        switchMap((organizationDto) => this.dialog.open<OrganizationCreateComponent, unknown, { organization: Organization }>(OrganizationCreateComponent, {
          width: DEFAULT_MODAL_WIDTH,
          data: organizationDto,
          closeOnNavigation: true
        }).afterClosed()),
        filter(v => Boolean(v?.organization)),
        withLatestFrom(this.countryService.countriesMap$, this.applicationService.applicationsMap$),
        tap(([v, countriesMap, applicationsMap]) => {
          this.updateOrganizationRowData(organization, v?.organization!, countriesMap, applicationsMap, event.api);
        })
      ).subscribe();
  }

  override updatePagination(event: PageEvent): void {
    super.updatePagination(event);
    this.organizationService.setPagination(event);
  }

  private initGridSetting(): void {
    this.gridState = {
      pending$: this.organizationService.pendingList$,
      data$: this.organizationService.getOrganizations(this.status!)
    };
  }

  private updateOrganizationRowData(organizationRowData: Organization,
                                    organizationUpdateData: Organization,
                                    countriesMap: Map<number, Country>,
                                    applicationsMap: Map<number, Application>,
                                    api: GridApi): void {
    Object.assign(
      organizationRowData,
      organizationUpdateData, {
        countryObj: countriesMap.get(organizationUpdateData.country!),
        applicationsObj: organizationUpdateData.applications?.map((a) => applicationsMap.get(a))
      }
    );
    api.applyTransaction({ update: [organizationRowData] });
  }
}
