import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {APP_INITIALIZER, ApplicationConfig, importProvidersFrom, Provider} from '@angular/core';
import {provideAnimations} from '@angular/platform-browser/animations';
import {provideRouter} from '@angular/router';
import {environment} from '@environments/environment';
import {KeycloakBearerInterceptor, KeycloakEventType, KeycloakService} from 'keycloak-angular';
import {KeycloakEvent} from 'keycloak-angular/lib/core/interfaces/keycloak-event';
import {provideEnvironmentNgxMask} from 'ngx-mask';
import {NgxPermissionsModule, NgxPermissionsService} from 'ngx-permissions';
import {catchError, filter, of, switchMap, take, tap} from 'rxjs';

import {AppInitializerService} from '@core/app-initializer/app-initializer.service';
import {UserPermissions, UserRole} from '@features/users/models';
import {UserApiService} from '@features/users/services';

import {appRoutes} from './app.routes';

const KeycloakInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeKeycloak,
  deps: [KeycloakService, AppInitializerService],
  multi: true
};

const KeycloakBearerInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: KeycloakBearerInterceptor,
  multi: true
};

const InitializePermissionsProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializePermissions,
  deps: [KeycloakService, NgxPermissionsService, AppInitializerService, UserApiService],
  multi: true
};

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keycloakUrl,
        realm: environment.keycloakRealm,
        clientId: environment.keycloakClientId
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html'
      }
    }).catch((e: unknown) => {
      if ((e as { error: string })?.error === 'access_denied') {
        void keycloak.logout();
      }
    });
}

function initializePermissions(
  keycloakService: KeycloakService,
  ps: NgxPermissionsService,
  appInitializerService: AppInitializerService,
  userApi: UserApiService
) {
  return () =>
    keycloakService.keycloakEvents$.pipe(
      filter((e: KeycloakEvent) => e.type === KeycloakEventType.OnReady),
      switchMap(() => userApi.getMyProfile().pipe(
        catchError(() => of({ assetMapping: false, eventReporting: false }))
      )),
      take(1),
      tap((profile) => {
        const permissions = [];
        permissions.push(...keycloakService.getUserRoles());
        if (!keycloakService.isUserInRole(UserRole.TECHNICIAN)) {
          if (profile.assetMapping) {
            permissions.push(UserPermissions.ASSET_MAPPING);
          }
          if (profile.eventReporting) {
            permissions.push(UserPermissions.EVENT_REPORTING);
          }
        }
        ps.loadPermissions(permissions);
        appInitializerService.init(permissions);
      })
    );
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    KeycloakInitializerProvider,
    KeycloakBearerInterceptorProvider,
    KeycloakService,
    importProvidersFrom(
      NgxPermissionsModule.forRoot()
    ),
    provideEnvironmentNgxMask(),
    InitializePermissionsProvider,
    provideRouter(appRoutes)
  ]
};
