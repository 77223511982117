import {HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  finalize,
  Observable,
  ReplaySubject,
  switchMap,
  tap,
  throwError
} from 'rxjs';

import {ListResponse, Maybe, Pagination} from '@core/common';
import {UserDto, UserSearchParams, UserStatus} from '@features/users/models';
import {AgGridSortState} from '@shared/models';

import {UserApiService} from './user-api.service';

@Injectable({ providedIn: 'root' })
export class UserService {
  private readonly userApiService = inject(UserApiService);

  private _refreshList$ = new BehaviorSubject<boolean>(false);
  private _pendingList$ = new BehaviorSubject<boolean>(false);
  private _errorList$ = new BehaviorSubject<Maybe<HttpErrorResponse>>(null);
  private _getByIdPending$ = new BehaviorSubject<boolean>(false);
  private _createPending$ = new BehaviorSubject<boolean>(false);
  private _updatePending$ = new BehaviorSubject<boolean>(false);
  private _deletePending$ = new BehaviorSubject<boolean>(false);
  private pagination$ = new ReplaySubject<Pagination>(1);
  private sortingState$ = new BehaviorSubject<Maybe<AgGridSortState[]>>(null);

  pendingList$ = this._pendingList$.asObservable();
  errorList$ = this._errorList$.asObservable();
  getByIdPending$ = this._getByIdPending$.asObservable();
  createPending$ = this._createPending$.asObservable();
  updatePending$ = this._updatePending$.asObservable();
  deletePending$ = this._deletePending$.asObservable();

  getList(status?: UserStatus): Observable<ListResponse<UserDto>> {
    return combineLatest([
      this.pagination$.asObservable(),
      this.sortingState$.asObservable(),
      this._refreshList$
    ])
      .pipe(
        tap(() => {
          this._pendingList$.next(true);
          this._errorList$.next(null);
        }),
        switchMap(([pagination, sorting]) => this.userApiService.getList(pagination, status, sorting)),
        tap(() => {
          this._pendingList$.next(false);
        }),
        catchError((err: HttpErrorResponse) => {
          this._errorList$.next(err);
          this._pendingList$.next(false);
          return throwError(() => err);
        })
      );
  }

  getById(id: string): Observable<UserDto> {
    this._getByIdPending$.next(true);
    return this.userApiService.getById(id)
      .pipe(finalize(() => {
        this._getByIdPending$.next(false);
      }));
  }

  create(user: UserDto): Observable<UserDto> {
    this._createPending$.next(true);
    return this.userApiService.create(user)
      .pipe(
        finalize(() => {
          this._createPending$.next(false);
        }),
        tap(() => {
          this.refreshList();
        })
      );
  }

  update(user: UserDto): Observable<UserDto> {
    this._updatePending$.next(true);
    return this.userApiService.update(user)
      .pipe(
        finalize(() => {
          this._updatePending$.next(false);
        })
      );
  }

  changeStatus(id: string, status: UserStatus): Observable<UserDto> {
    this._deletePending$.next(true);
    return this.userApiService.changeStatus(id, status)
      .pipe(
        finalize(() => {
          this._deletePending$.next(false);
        }),
        tap(() => {
          this.refreshList();
        })
      );
  }

  refreshList(): void {
    this._refreshList$.next(!this._refreshList$.value);
  }

  setPagination(pagination: Pagination): void {
    this.pagination$.next(pagination);
  }

  setSorting(event: AgGridSortState[] | null): void {
    this.sortingState$.next(event);
  }

  findUsersByEmail(email: string): Observable<ListResponse<UserDto>> {
    const params = {
      email,
      exact: true
    } as UserSearchParams;
    return this.userApiService.search(params);
  }
}
