import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  finalize,
  Observable,
  ReplaySubject,
  switchMap,
  tap,
  throwError
} from 'rxjs';

import {BackendError, ErrorCode, ListResponse, Maybe, Pagination} from '@core/common';
import {Project, ProjectCreateReq, ProjectStatus} from '@features/projects/models';
import {AgGridSortState, ProjectFilter} from '@shared/models';

import {ProjectApiService} from './project-api.service';

@Injectable({ providedIn: 'root' })
export class ProjectService {
  private readonly projectApiService = inject(ProjectApiService);
  private _refreshList$ = new BehaviorSubject<boolean>(false);
  private _pendingList$ = new BehaviorSubject<boolean>(false);
  private _errorList$ = new BehaviorSubject<Maybe<HttpErrorResponse>>(null);
  private _getByIdPending$ = new BehaviorSubject<boolean>(false);
  private _createPending$ = new BehaviorSubject<boolean>(false);
  private _updatePending$ = new BehaviorSubject<boolean>(false);
  private _deletePending$ = new BehaviorSubject<boolean>(false);
  private pagination$ = new ReplaySubject<Pagination>(1);
  private sortingState$ = new BehaviorSubject<Maybe<AgGridSortState[]>>(null);
  private filterState$ = new BehaviorSubject<Maybe<ProjectFilter>>(null);

  pendingList$ = this._pendingList$.asObservable();
  errorList$ = this._errorList$.asObservable();
  getByIdPending$ = this._getByIdPending$.asObservable();
  createPending$ = this._createPending$.asObservable();
  updatePending$ = this._updatePending$.asObservable();
  deletePending$ = this._deletePending$.asObservable();

  getProjects(status: ProjectStatus): Observable<ListResponse<Project>> {
    return combineLatest([
      this.pagination$.asObservable(),
      this.sortingState$.asObservable(),
      this.filterState$.asObservable(),
      this._refreshList$
    ])
      .pipe(
        tap(() => {
          this._pendingList$.next(true);
          this._errorList$.next(null);
        }),
        switchMap(([pagination, sorting, filter]) => this.projectApiService.getProjects(pagination, status, sorting, filter)
          .pipe(
            finalize(() => {
              this._pendingList$.next(false);
            }),
            catchError((err: HttpErrorResponse) => {
              this._errorList$.next(err);
              return throwError(() => err);
            })
          ))
      );
  }

  getById(id: number): Observable<Project> {
    this._getByIdPending$.next(true);
    return this.projectApiService.getById(id)
      .pipe(finalize(() => {
        this._getByIdPending$.next(false);
      }));
  }

  create(projectCreateReq: ProjectCreateReq): Observable<Project> {
    this._createPending$.next(true);
    return this.projectApiService.create(projectCreateReq)
      .pipe(
        finalize(() => {
          this._createPending$.next(false);
        }),
        tap(() => {
          this.refreshList();
        })
      );
  }

  update(project: Project): Observable<Project> {
    this._updatePending$.next(true);
    return this.projectApiService.update(project)
      .pipe(
        tap(() => this.refreshList()),
        finalize(() => this._updatePending$.next(false)),
      );
  }

  updateStatus(projectId: number, isActive: boolean): Observable<void> {
    this._updatePending$.next(true);
    return this.projectApiService.updateStatus(projectId, isActive)
      .pipe(
        tap(() => this.refreshList()),
        finalize(() => this._updatePending$.next(false))
      );
  }

  searchByName(projectName: string): Observable<ListResponse<Project>> {
    return this.projectApiService.searchByName(projectName);
  }

  searchByRefCode(projectName: string): Observable<ListResponse<Project>> {
    return this.projectApiService.searchByRefCode(projectName);
  }

  uploadFile(file: File): Observable<string> {
    return this.projectApiService.uploadFile(file);
  }

  updateFile(projectId: number, file: File): Observable<string> {
    return this.projectApiService.updateFile(projectId, file);
  }

  uploadFiberRouteFile(projectId: number, file: File): Observable<Project> {
    return this.projectApiService.uploadFiberRouteFile(projectId, file).pipe(
      catchError((err: unknown) => {
        // Temp solution. Replace when error codes support will be added to backend
        if (err instanceof HttpErrorResponse && (err.status as HttpStatusCode) === HttpStatusCode.BadRequest) {
          if (typeof err?.error === 'string' && err?.error?.includes('Parsing exception')) {
            return throwError(() => new BackendError(
              'File format incorrect. CSV file must contain four columns, separated by semicolon',
              ErrorCode.INVALID_CSV
            ));
          }
          if (err?.error?.isValidFile === false) {
            return throwError(() => new BackendError(
              'The file contains more than one reference route. Please select a file with only one reference route.',
              ErrorCode.INVALID_KML_KMZ
            ));
          }
        }
        return throwError(() => err as Error);
      })
    );
  }

  getProjectFileByName(projectId: number, fileName: string): Observable<Blob> {
    return this.projectApiService.getProjectFileByName(projectId, fileName);
  }

  refreshList(): void {
    this._refreshList$.next(!this._refreshList$.value);
  }

  setPagination(pagination: Pagination): void {
    this.pagination$.next(pagination);
  }

  setFilter(filter: ProjectFilter): void {
    this.filterState$.next(filter);
  }

  setSorting(event: AgGridSortState[] | null): void {
    this.sortingState$.next(event);
  }
}
